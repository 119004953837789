import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { SectionType, StudentSection } from 'minga/domain/studentSchedule';

import { StudentSCheduleWidgetMessage } from './student-schedule-widget.constants';

@Component({
  selector: 'mg-student-schedule-widget',
  templateUrl: './student-schedule-widget.component.html',
  styleUrls: ['./student-schedule-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentScheduleWidgetComponent {
  /** Constants */
  public readonly MESSAGES = StudentSCheduleWidgetMessage;
  public readonly SectionType = SectionType;

  @Input() scheduleItems: StudentSection[];
  @Input() bellScheduleEnabledForCurrentUser: boolean = false;
  @Output() public viewAllPressed = new EventEmitter<{
    isFlexActivity: boolean;
    schedule?: StudentSection;
  }>();

  constructor() {}

  public goToSchedule(schedule?: StudentSection) {
    this.viewAllPressed.emit({
      isFlexActivity: schedule?.type === SectionType.FLEX,
      schedule,
    });
  }
}
