<button
  (click)="onScheduleClicked(class)"
  *ngFor="let class of data"
  class="container btn-reset">
  <!-- Activity icon -->
  <mg-icon
    class="activity-icon"
    [iconName]="
      class.type === SectionType.FLEX ? 'mg-flextime-menu-o' : 'mg-schedule'
    ">
  </mg-icon>
  <!-- Class name -->
  <mg-text
    class="class-name"
    variant="header-md"
    [numberOfLines]="1">
    {{ class.name }}
  </mg-text>
  <mg-icon
    class="location-icon"
    iconName="mg-location-on">
  </mg-icon>
  <!-- Location -->
  <mg-text variant="body-md">{{ class.location }}</mg-text>
</button>
