import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SectionType, StudentSection } from 'minga/domain/studentSchedule';

@Component({
  selector: 'mg-view-id-schedule',
  templateUrl: './view-id-schedule.component.html',
  styleUrls: ['./view-id-schedule.component.scss'],
})
export class ViewIdScheduleComponent {
  // Inputs

  @Input()
  data: StudentSection[];

  @Output() scheduleClicked = new EventEmitter<StudentSection>();

  public readonly SectionType = SectionType;

  /** Constructor */
  constructor() {}

  public onScheduleClicked(section: StudentSection): void {
    this.scheduleClicked.emit(section);
  }
}
