import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
} from '@angular/core';

import { NgSelectComponent } from '@ng-select/ng-select';
import { BehaviorSubject } from 'rxjs';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { FormLabelBackground } from '../../types';
import { FormGroupedSelectBaseDirective } from './form-grouped-select-base.util';
import { CategoryItem, OptionItem } from './form-grouped-select.types';

@Component({
  selector: 'mg-form-grouped-select',
  templateUrl: './form-grouped-select.component.html',
  styleUrls: ['./form-grouped-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGroupedSelectComponent extends FormGroupedSelectBaseDirective {
  @ViewChild('select') selectComponent: NgSelectComponent;

  @Input() set value(value: OptionItem[] | OptionItem | null) {
    if (!value || (Array.isArray(value) && !value.length)) {
      this.reset(true);
      return;
    }

    this.selectedValue = value;
    this.onSelect(this.selectedValue, false);
  }

  @Input() labelBackground: FormLabelBackground = 'white';
  @Input() floatingLabel = true;
  @Input() placeholder: string;
  @Input() appendTo: string;

  private readonly _isOpenSubj = new BehaviorSubject<boolean>(false);
  public readonly isOpen$ = this._isOpenSubj.asObservable();

  constructor(
    public cdr: ChangeDetectorRef,
    public snackBar: SystemAlertSnackBarService,
  ) {
    super(cdr, snackBar, null);
  }

  get showLabel() {
    if (!this.floatingLabel) return false;
    if (this._isOpenSubj.value) return true;

    return this._hasValue(this.selectedValue);
  }

  public groupByFn(item) {
    return item?.category?.value;
  }

  public groupValueFn(groupKey: string, children: OptionItem[]) {
    return {
      label: children?.[0].category.label,
    };
  }

  public focus() {
    this.selectComponent.focus();
  }

  public onBlur(event) {
    this.reset();
  }

  public async setOpenState(val: boolean) {
    this._isOpenSubj.next(val);

    const lastOpenedCategory = this._getLastOpenedCategory();
    const handleOnOpen = val && !this.resetMenuOnClose;

    if (handleOnOpen && lastOpenedCategory) {
      // had to wrap in timemout to work with ng-select for some reason, cdr change didnt work
      setTimeout(() => {
        this.setCategory(lastOpenedCategory, false);
      }, 1);
    }
  }

  public onSelect(event: OptionItem | OptionItem[] | null, emitChange = true) {
    if (emitChange) {
      this.selectChange.emit(event);
    }
    this.reset();
  }

  public compareWithFn(o1: OptionItem, o2: OptionItem) {
    return o1?.value === o2?.value;
  }

  protected _getLastOpenedCategory(): CategoryItem | null {
    const fallback = this._categoryOpenByDefault;
    const currentCategory = this.selectedValue
      ? this._getCategory(
          Array.isArray(this.selectedValue)
            ? this.selectedValue[this.selectedValue.length - 1].category?.value
            : this.selectedValue.category?.value,
        )
      : null;

    // 1. If the user has interacted at all with the dropdown lets try and
    // return them to where they were
    if (this._lastSelectedCategory || this._lastSelectedCategory === null) {
      return this._lastSelectedCategory;
    }

    // 2. If there is a selected value lets return them to the category
    // associated with it
    if (currentCategory) return currentCategory;

    // 3. Otherwise fallback to any default set in the options config
    return fallback;
  }
}
