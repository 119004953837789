<div
  #container
  class="container">
  <ng-container *ngIf="studentData.active; else inactiveTemplate">
    <div class="user-info logo-backdrop">
      <div class="backdrop-animation">
        <div class="gradient-wrap">
          <div class="gradient"></div>
        </div>
      </div>

      <div class="avatar">
        <img
          class="id-picture"
          [src]="
            studentData.idPhoto
              ? domSanitizer.bypassSecurityTrustUrl(studentData.idPhoto)
              : 'assets/student-id/placeholder-profile-pic.png'
          " />
        <mg-icon
          *ngIf="!readonly && ('ID_CHANGE_OWN_PHOTO' | hasPermission)"
          iconName="studio-image-active"
          class="edit-icon"
          (click)="changePhoto.emit(studentData)"></mg-icon>
      </div>
      <section>
        <mg-text
          class="dynamic-font-color"
          [variant]="
            (media.isMobileView$ | async) ? 'header-md' : 'body-md-secondary'
          "
          textAlign="center">
          {{ studentData.mingaYear | schoolYearFormat: 'mingasso' }}
        </mg-text>
        <mg-text
          class="dynamic-font-color"
          variant="header-xxl"
          textAlign="center">
          {{ studentData.firstName }} {{ studentData.lastName }}
        </mg-text>
      </section>
      <div class="divider"></div>
      <section>
        <div
          fxLayout="row"
          fxLayoutAlign="center start"
          fxLayoutGap="8px">
          <mg-text
            *ngIf="settings.idShowGrade && studentData.grade"
            class="dynamic-font-color"
            textAlign="center"
            [variant]="(media.isMobileView$ | async) ? 'body-md' : 'body-sm'">
            Grade {{ studentData.grade }}
          </mg-text>
          <div
            *ngIf="
              settings.idShowGrade && studentData.grade && settings.idShowRole
            "
            class="separator"
            fxFlex="nogrow"
            fxFlexAlign="center"></div>
          <mg-text
            *ngIf="settings.idShowRole"
            class="dynamic-font-color"
            textAlign="center"
            [variant]="(media.isMobileView$ | async) ? 'body-md' : 'body-sm'">
            {{ studentData.role }}
          </mg-text>
        </div>
        <mg-text
          *ngIf="settings.idShowIdField1"
          class="dynamic-font-color"
          textAlign="center"
          [variant]="(media.isMobileView$ | async) ? 'body-md' : 'body-sm'">
          {{ studentData.customField1 }}
        </mg-text>
        <mg-text
          *ngIf="settings.idShowIdField2"
          class="dynamic-font-color"
          textAlign="center"
          [variant]="(media.isMobileView$ | async) ? 'body-md' : 'body-sm'">
          {{ studentData.customField2 }}
        </mg-text>
      </section>
    </div>
  </ng-container>
  <div class="minga-info">
    <div class="school-info">
      <mg-image
        class="school-logo"
        [background-size]="'contain'"
        [srcs]="[settings?.idLogoAssetPath]">
      </mg-image>
      <div class="tw-flex tw-flex-column">
        <mg-text
          [variant]="
            (media.isMobileView$ | async)
              ? 'body-md-secondary'
              : 'body-sm-secondary'
          "
          [numberOfLines]="2">
          {{ settings.idSchoolName }}
        </mg-text>
        <mg-text
          [variant]="(media.isMobileView$ | async) ? 'body-md' : 'body-sm'"
          [numberOfLines]="2">
          {{ studentData.mingaAddress }}
        </mg-text>
      </div>
    </div>
    <div class="student-barcode">
      <mg-view-id-code
        *ngIf="studentData.active"
        [idNumber]="studentData.studentId"
        [format]="settings.idBarcodeType"
        [class.bar128]="settings.idBarcodeType === ViewIdCodeFormats.BAR_128"
        [hideIdNumber]="settings | hideIdNumber: readonly">
      </mg-view-id-code>
    </div>
  </div>
</div>

<ng-template #inactiveTemplate>
  <div class="inactive">
    <mg-text variant="header-xxl">ID Inactive</mg-text>
    <mg-icon iconName="mg-id-inactive"></mg-icon>
    <div class="inactive-details">
      <mg-text variant="header-md">Your ID card is currently locked</mg-text>
      <mg-text
        variant="body-md"
        textAlign="center">
        Please see a school administrator for more information
      </mg-text>
    </div>
  </div>
</ng-template>
