import { NavigationExtras, Params } from '@angular/router';

import * as day from 'dayjs';

export enum QueryParamKey {
  // global filtering keys
  START_DATE = 'start_date', // range
  END_DATE = 'end_date', // range
  DATE = 'date', // single date

  // admin tools
  STAT_RANGE_PRESET = 'stat_range_preset',

  // bell schedule
  DAY = 'day', // index of day of the week
  TERM = 'term', // active bell schedule term
}

type Range = {
  startDate: day.Dayjs;
  endDate: day.Dayjs;
};

const DATE_FORMAT = 'YYYY-MM-DD';

/**
 * Takes a date range and returns an object formatted for query params
 */
export const getQueryParamsFromDateRange = (
  range: Range,
  fallback?: Range,
): {
  start_date: string;
  end_date: string;
} => {
  const start = range?.startDate || fallback?.startDate || day();
  const end = range?.endDate || fallback?.endDate || day();
  return {
    [QueryParamKey.START_DATE]: start.format(DATE_FORMAT),
    [QueryParamKey.END_DATE]: end.format(DATE_FORMAT),
  };
};

/**
 * Takes query params and returns a date range object
 */
export const getDateRangeFromQueryParams = (
  queryParams: Params,
  fallback: Range,
): Range => {
  const startDate = queryParams?.[QueryParamKey.START_DATE]
    ? day(queryParams.start_date)
    : fallback.startDate;
  const endDate = queryParams?.[QueryParamKey.END_DATE]
    ? day(queryParams.end_date)
    : fallback.endDate;

  // generally for range we want the start of start date and end of end date
  // this mimics the behavior of the date range picker
  return {
    startDate: startDate.startOf('day'),
    endDate: endDate.endOf('day'),
  };
};

/**
 * Single date helper method for query param to date object
 */
export const getQueryParamFromDate = (
  date: day.Dayjs,
  fallback: day.Dayjs = day(),
): { [QueryParamKey.DATE]: string } => {
  const finalDate = date || fallback;
  return {
    [QueryParamKey.DATE]: finalDate.format(DATE_FORMAT),
  };
};

/**
 * Single date helper method for date to query param
 */
export const getDateFromQueryParam = (
  queryParams: Params,
  fallback: day.Dayjs = day(),
): day.Dayjs => {
  const finalDate = queryParams[QueryParamKey.DATE]
    ? day(queryParams[QueryParamKey.DATE])
    : fallback;
  return finalDate.startOf('day');
};

/**
 * Global config for filtering behavior, main behavior we care about is
 * replacing vs pushing new url state
 */
export const defaultFilteringNavigationBehaviors: NavigationExtras = {
  replaceUrl: false,
};
