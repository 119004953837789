<div
  #container
  class="container">
  <div class="id-info logo-backdrop">
    <div class="backdrop-animation">
      <div class="gradient-wrap">
        <div class="gradient"></div>
      </div>
    </div>

    <div class="school-info">
      <div class="minga-info">
        <mg-image
          class="school-logo"
          [background-size]="'contain'"
          [srcs]="[settings?.idLogoAssetPath]">
        </mg-image>
        <mg-text
          variant="header-sm"
          class="dynamic-font-color"
          [numberOfLines]="3">
          {{ settings.idSchoolName }}
        </mg-text>
      </div>
      <mg-text
        class="dynamic-font-color"
        textAlign="right"
        [variant]="(media.isMobileView$ | async) ? 'body-sm' : 'body-md'">
        {{ studentData.mingaYear | schoolYearFormat: 'mingalangelo' }}
      </mg-text>
    </div>

    <div class="divider"></div>

    <ng-container *ngIf="studentData.active; else inactiveTemplate">
      <div class="user-info">
        <div class="avatar">
          <img
            class="id-picture"
            [src]="
              studentData.idPhoto
                ? domSanitizer.bypassSecurityTrustUrl(studentData.idPhoto)
                : 'assets/student-id/placeholder-profile-pic.png'
            " />
          <mg-icon
            *ngIf="!readonly && ('ID_CHANGE_OWN_PHOTO' | hasPermission)"
            iconName="studio-image-active"
            class="edit-icon"
            (click)="changePhoto.emit(studentData)"></mg-icon>
        </div>
        <div class="info-info">
          <mg-text
            variant="header-xl-secondary"
            class="dynamic-font-color">
            {{ studentData.firstName }} {{ studentData.lastName }}
          </mg-text>
          <div
            fxLayout="row"
            fxLayoutAlign="start start"
            fxLayoutGap="8px">
            <mg-text
              *ngIf="settings.idShowGrade && studentData.grade"
              class="dynamic-font-color"
              textAlign="center"
              [variant]="(media.isMobileView$ | async) ? 'body-sm' : 'body-md'">
              Grade {{ studentData.grade }}
            </mg-text>
            <div
              *ngIf="
                settings.idShowGrade && studentData.grade && settings.idShowRole
              "
              class="separator"
              fxFlex="nogrow"
              fxFlexAlign="center"></div>
            <mg-text
              *ngIf="settings.idShowRole"
              class="dynamic-font-color"
              textAlign="center"
              [variant]="(media.isMobileView$ | async) ? 'body-sm' : 'body-md'">
              {{ studentData.role }}
            </mg-text>
          </div>
          <mg-text
            *ngIf="settings.idShowIdField1"
            class="dynamic-font-color"
            [variant]="(media.isMobileView$ | async) ? 'body-sm' : 'body-md'">
            {{ studentData.customField1 }}
          </mg-text>
          <mg-text
            *ngIf="settings.idShowIdField2"
            class="dynamic-font-color"
            [variant]="(media.isMobileView$ | async) ? 'body-sm' : 'body-md'">
            {{ studentData.customField2 }}
          </mg-text>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="barcode-info">
    <mg-view-id-code
      *ngIf="studentData.active"
      [idNumber]="studentData.studentId"
      [format]="settings.idBarcodeType"
      [class.bar128]="settings.idBarcodeType === ViewIdCodeFormats.BAR_128"
      [hideIdNumber]="settings | hideIdNumber: readonly">
    </mg-view-id-code>
  </div>
</div>

<ng-template #inactiveTemplate>
  <div class="inactive">
    <mg-text variant="header-xxl">ID Inactive</mg-text>
    <mg-icon iconName="mg-id-inactive"></mg-icon>
    <div class="inactive-details">
      <mg-text variant="header-md">Your ID card is currently locked</mg-text>
      <mg-text
        variant="body-md"
        textAlign="center">
        Please see a school administrator for more information
      </mg-text>
    </div>
  </div>
</ng-template>
