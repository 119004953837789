import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mg-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormErrorComponent {
  @Input() variant: 'error' | 'warning' = 'error';

  get variantToColor(): string {
    const variants = {
      error: 'error',
      warning: 'warn',
    };
    return variants[this.variant] || variants.error;
  }
}
