<!-- Main Overlay -->
<mg-overlay-primary
  size="auto"
  [isLoading]="(formState$ | async) === 'loading' || (loadingData$ | async)"
  [overlayTitle]="
    (isNew$ | async) ? MESSAGES.MODAL_TITLE_ADD : MESSAGES.MODAL_TITLE_EDIT
  ">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content Template -->
<ng-template #mainTemplate>
  <main
    class="modal-form"
    #crudForm>
    <form
      id="bs-schedule-form"
      [formGroup]="form"
      (ngSubmit)="submit()">
      <div
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="24px">
        <section *ngIf="(formState$ | async) === 'invalid'">
          <fieldset>
            <mg-system-alert
              type="error"
              [message]="MESSAGES.ERROR_GENERIC"></mg-system-alert>
          </fieldset>
        </section>
        <section>
          <fieldset>
            <div
              fxLayout="column"
              fxLayoutAlign="start stretch"
              fxLayoutGap="12px">
              <mg-form-text-input
                [label]="MESSAGES.FIELD_LABEL_NAME"
                [control]="form.get(FORM_FIELDS.NAME)">
              </mg-form-text-input>

              <fieldset class="periods">
                <div class="header">
                  <mg-text
                    class="mobile"
                    variant="body-xs"
                    >{{ MESSAGES.MOBILE_PERIOD_HEADER }}</mg-text
                  >
                  <div class="desktop">
                    <div class="header-row">
                      <div class="code">
                        <mg-text
                          variant="label-md-secondary"
                          color="outline"
                          >{{ MESSAGES.PERIOD_TABLE_SHORT_CODE }}</mg-text
                        >
                        <mg-tooltip [label]="MESSAGES.FIELD_PERIOD_TOOLTIP">
                        </mg-tooltip>
                      </div>
                      <div class="name">
                        <mg-text
                          variant="label-md-secondary"
                          color="outline"
                          >{{ MESSAGES.PERIOD_TABLE_PERIOD }}</mg-text
                        >
                      </div>
                      <div class="start">
                        <mg-text
                          variant="label-md-secondary"
                          color="outline"
                          >{{ MESSAGES.PERIOD_TABLE_START_TIME }}</mg-text
                        >
                      </div>
                      <div class="end">
                        <mg-text
                          variant="label-md-secondary"
                          color="outline"
                          >{{ MESSAGES.PERIOD_TABLE_END_TIME }}</mg-text
                        >
                      </div>
                      <div class="delete"></div>
                    </div>
                  </div>
                </div>
                <div class="items">
                  <ng-container
                    *ngIf="periodWarnings$ | async as periodWarnings">
                    <div
                      *ngFor="let period of periods.controls; let i = index"
                      fxLayout="column"
                      fxLayoutGap="8px"
                      class="period">
                      <div
                        class="period-form"
                        [ngClass]="{
                          'overlap-error': periodWarnings[i]?.overlap
                        }">
                        <div class="code">
                          <mg-form-select
                            [addTag]="addShortCode"
                            [addTagText]="MESSAGES.FIELD_PERIOD_SHORT_CODE_ADD"
                            [condensed]="true"
                            [searchable]="true"
                            [floatingLabel]="false"
                            [options]="showCodeOptions$ | async"
                            [suppressErrorMessage]="true"
                            [control]="period.get(PERIOD_FIELDS.SHORT_CODE)">
                          </mg-form-select>
                        </div>
                        <div class="name">
                          <mg-form-text-input
                            [suppressErrorMessage]="true"
                            [control]="period.get(PERIOD_FIELDS.NAME)"
                            [condensed]="true">
                          </mg-form-text-input>
                        </div>
                        <div class="start">
                          <mg-form-text-input
                            [suppressErrorMessage]="true"
                            [control]="period.get(PERIOD_FIELDS.START_TIME)"
                            [condensed]="true"
                            inputType="time"
                            [step]="60">
                          </mg-form-text-input>
                        </div>
                        <div class="end">
                          <mg-form-text-input
                            [suppressErrorMessage]="true"
                            [control]="period.get(PERIOD_FIELDS.END_TIME)"
                            [condensed]="true"
                            inputType="time"
                            [step]="60">
                          </mg-form-text-input>
                        </div>
                        <div class="delete">
                          <mg-btn
                            variant="icon"
                            iconSet="minga"
                            icon="mg-delete"
                            [disabled]="periods.length === 1"
                            (pressed)="deletePeriod(i)">
                          </mg-btn>
                        </div>
                      </div>

                      <div
                        fxLayout="column"
                        fxLayoutGap="4px"
                        class="period-errors">
                        <mg-form-error
                          *ngIf="
                            period.get(PERIOD_FIELDS.SHORT_CODE).invalid &&
                            period.get(PERIOD_FIELDS.SHORT_CODE).touched
                          ">
                          {{ MESSAGES.SHORT_CODE_ERROR }}
                        </mg-form-error>
                        <mg-form-error
                          *ngIf="
                            period.get(PERIOD_FIELDS.NAME).invalid &&
                            period.get(PERIOD_FIELDS.NAME).touched
                          ">
                          {{ MESSAGES.PERIOD_NAME_ERROR }}
                        </mg-form-error>
                        <mg-form-error
                          *ngIf="
                            period.get(PERIOD_FIELDS.START_TIME).touched &&
                            period.get(PERIOD_FIELDS.END_TIME).touched &&
                            period.getError('invalidTimeComparison')
                          ">
                          {{ period?.errors | makeInputErrorMessage }}
                        </mg-form-error>
                        <mg-form-error
                          *ngIf="periodWarnings[i]?.overlap"
                          variant="warning">
                          {{ periodWarnings[i].overlap }}
                        </mg-form-error>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="actions">
                  <mg-btn
                    variant="outlined"
                    size="small"
                    (pressed)="addPeriod()"
                    >{{ MESSAGES.ADD_PERIOD_BTN }}</mg-btn
                  >
                </div>
              </fieldset>
            </div>
          </fieldset>
          <fieldset>
            <legend>
              <div
                fxLayout="row"
                fxLayoutAlign="start center">
                <mg-text variant="header-sm-secondary">
                  {{ MESSAGES.FIELDSET_DEFAULT_DAYS_TITLE }}
                </mg-text>
                <mg-tooltip [label]="MESSAGES.FIELDSET_DEFAULT_DAYS_TOOLTIP">
                </mg-tooltip>
              </div>

              <mg-text
                variant="body-xs"
                color="surface-alt"
                textAlign="left">
                {{ MESSAGES.FIELDSET_DEFAULT_DAYS_DESC }}
              </mg-text>
            </legend>
            <div
              fxLayout="column"
              fxLayoutAlign="start stretch"
              fxLayoutGap="12px">
              <mg-weekday-toggle
                [initialValue]="form.get(FORM_FIELDS.DAYS).value"
                (valueChanged)="updateActiveDays($event)"
                [invalidDays]="form.errors?.overlappingDaysByTerm">
              </mg-weekday-toggle>
              <mg-form-error *ngIf="form.getError('overlappingDaysByTerm')">
                {{ MESSAGES.DAYS_ALREADY_USED_ERROR }}
              </mg-form-error>
              <mg-form-error
                *ngIf="
                  form.get(FORM_FIELDS.DAYS).invalid &&
                  form.get(FORM_FIELDS.DAYS).touched
                ">
                {{ MESSAGES.SELECT_DAY_ERROR }}
              </mg-form-error>
            </div>
          </fieldset>
          <fieldset>
            <legend>
              <div
                fxLayout="row"
                fxLayoutAlign="start center">
                <mg-text variant="header-sm-secondary">
                  {{ MESSAGES.FIELDSET_TERMS_TITLE }}
                </mg-text>
                <mg-tooltip [label]="MESSAGES.FIELDSET_TERMS_TOOLTIP">
                </mg-tooltip>
              </div>

              <mg-text
                variant="body-xs"
                color="surface-alt"
                textAlign="left">
                {{ MESSAGES.FIELDSET_TERMS_DESC }}
              </mg-text>
            </legend>
            <div
              fxLayout="column"
              fxLayoutAlign="start stretch"
              fxLayoutGap="12px">
              <mg-form-select
                appendTo="body"
                [isFullWidth]="true"
                [multiple]="true"
                [notFoundText]="MESSAGES.FIELD_SELECT_NOT_FOUND"
                [placeholder]="MESSAGES.FIELD_LABEL_TERM"
                [options]="termsOptions$ | async"
                [control]="form.get(FORM_FIELDS.TERMS)">
              </mg-form-select>
            </div>
          </fieldset>
        </section>
      </div>
    </form>
  </main>
</ng-template>

<ng-template #footerTemplate>
  <footer
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-btn
        [variant]="(isNew$ | async) === true ? 'text' : 'destructive'"
        (pressed)="confirmDelete()">
        {{ deleteLabel }}
      </mg-btn>
    </div>
    <div>
      <mg-btn
        attrType="submit"
        attrFormId="bs-schedule-form"
        variant="filled"
        [disabled]="!canSubmit">
        {{ submitLabel }}
      </mg-btn>
    </div>
  </footer>
</ng-template>
