import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

import * as day from 'dayjs';
import clamp from 'lodash/clamp';
import { BehaviorSubject, combineLatest, from, of, ReplaySubject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  takeUntil,
} from 'rxjs/operators';

import { StudentSection } from 'minga/domain/studentSchedule';

import { BellSchedulePermissionsService } from '@modules/minga-manager/components/mm-bell-schedule/services/bell-schedule-permissions.service';

import { MediaService } from '@shared/services/media';
import { StudentScheduleService } from '@shared/services/student-schedule/student-schedule.service';
import {
  defaultFilteringNavigationBehaviors,
  QueryParamKey,
} from '@shared/utils';

import { FormSelectOption } from '../form';
import { SystemAlertSnackBarService } from '../system-alert-snackbar';
import {
  BELL_SCHEDULE_DESKTOP_DISPLAY_COLUMNS,
  STUDENT_SCHEDULE_DESKTOP_DISPLAY_COLUMNS,
  StudentScheduleMessage,
} from './student-schedule.constants';

@Component({
  selector: 'mg-student-schedule',
  templateUrl: './student-schedule.component.html',
  styleUrls: ['./student-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentScheduleComponent implements OnInit, AfterViewInit {
  /** Children */
  @ViewChild(MatSort) sort: MatSort;

  /** Inputs */
  @Input() hash: string;

  /** Constants */
  public readonly MESSAGES = StudentScheduleMessage;
  public DAY_QUERY_KEY = QueryParamKey.DAY;

  /** Observables */
  public currentWeek$ = of(day().startOf('week')).pipe(
    map(start => {
      return Array.from({ length: 7 }, (_, i) => start.add(i, 'day'));
    }),
  );

  public selectOptions$ = this.currentWeek$.pipe(
    map(dates => {
      return dates.map(date => ({
        value: date.day(),
        label: date.format('dddd'),
        date,
      }));
    }),
  );

  private _destroyedSubject = new ReplaySubject<void>(1);

  private _loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this._loadingSubject.asObservable();

  private _periodsFilterSubject = new BehaviorSubject<
    FormSelectOption<string>[]
  >([]);
  public periodsFilter$ = this._periodsFilterSubject.asObservable();

  private _activeSortColumnSubject = new BehaviorSubject<string>(null);
  public readonly activeSortColumn$ =
    this._activeSortColumnSubject.asObservable();

  /** Table */
  public readonly dataSource = new MatTableDataSource<StudentSection>([]);

  public isBellScheduleEnabledForCurrentUser$ = from(
    this._bellSchedulePermissions.isBellScheduleEnabledForCurrentUser(),
  );

  public readonly displayColumns$ = combineLatest([
    this.media.breakpoint$,
    this.isBellScheduleEnabledForCurrentUser$,
  ]).pipe(
    takeUntil(this._destroyedSubject),
    map(([bp, isTestModeEnabled]) => {
      if (['xsmall', 'small'].includes(bp)) {
        return ['mobile'];
      } else {
        return isTestModeEnabled
          ? [
              ...STUDENT_SCHEDULE_DESKTOP_DISPLAY_COLUMNS,
              ...BELL_SCHEDULE_DESKTOP_DISPLAY_COLUMNS,
            ]
          : STUDENT_SCHEDULE_DESKTOP_DISPLAY_COLUMNS;
      }
    }),
  );

  public searchControl = new FormControl('');

  private readonly _searchValue$ = this.searchControl.valueChanges.pipe(
    takeUntil(this._destroyedSubject),
    startWith(''),
    debounceTime(300),
    distinctUntilChanged(),
  );

  /**
   * Gonna be the query param for the active day of week, if nothing supplied fallback to today
   */
  public activeDayQueryParam$ = this.route.queryParams.pipe(
    map(params => {
      const defaultDay = day().day();

      if (isNaN(+params[QueryParamKey.DAY])) return defaultDay;

      return clamp(+params[QueryParamKey.DAY], 0, 6);
    }),
  );

  constructor(
    public media: MediaService,
    private _studentSchedule: StudentScheduleService,
    private _snackBarService: SystemAlertSnackBarService,
    public route: ActivatedRoute,
    private _router: Router,
    private _bellSchedulePermissions: BellSchedulePermissionsService,
  ) {}

  ngOnInit(): void {
    this._searchValue$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(search => {
        this.dataSource.filter = JSON.stringify({
          searchFilter: search.toLowerCase().trim(),
        });
      });

    this.activeDayQueryParam$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(queryDay => {
        let start = day().startOf('week').add(queryDay, 'day');
        start = start.isValid() ? start : day();
        this._getSchedule(this.hash, start, start);
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this._tableFilter;
  }

  public selectActiveColumn(columnKey: string) {
    this._activeSortColumnSubject.next(columnKey);
  }

  public setDayFilter(value: day.Dayjs) {
    this._router.navigate([], {
      queryParams: {
        [QueryParamKey.DAY]: value.day(),
      },
      ...defaultFilteringNavigationBehaviors,
    });
  }

  private async _getSchedule(hash, startDate, endDate) {
    try {
      this._loadingSubject.next(true);
      const schedule = await this._studentSchedule.getStudentSchedule(
        hash,
        startDate,
        endDate,
      );
      this._setSchedule(schedule);
    } catch (error) {
      this._snackBarService.error('There was a problem fetching schedule');
    } finally {
      this._loadingSubject.next(false);
    }
  }

  private _setSchedule(schedule: StudentSection[]) {
    this.dataSource.data = schedule;

    let periods = schedule
      .filter(s => s.period)
      .map(s => s.period)
      .sort((a, b) => {
        return a.localeCompare(b);
      });

    periods = [...new Set(periods)];

    this._periodsFilterSubject.next(
      periods.map(p => ({
        value: p,
        label: p,
      })),
    );
  }

  private _tableFilter(item: StudentSection, f) {
    const { searchFilter } = JSON.parse(f) as {
      searchFilter: string;
    };

    const { name = '', period = '', teachers = [], location = '' } = item;

    const searchMatch =
      name.toLowerCase().includes(searchFilter) ||
      period.toLowerCase().includes(searchFilter) ||
      location.toLowerCase().includes(searchFilter) ||
      teachers.join(', ').toLowerCase().includes(searchFilter);

    return searchMatch;
  }
}
