export enum PeopleViewProfileRoute {
  PROFILE = 'profile',
  DASHBOARD = 'dashboard',
  GROUPS = 'groups', // deprecated, we're leaving in for redirecting purposes
  BEHAVIOURS = 'behaviours',
  POINTS = 'points',
  FLEXTIME = 'flextime',
  CONTENT = 'content',
  SCHEDULE = 'schedule',
}
