import { Platform } from '@angular/cdk/platform';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
  BehaviorSubject,
  combineLatest,
  from,
  ReplaySubject,
  timer,
} from 'rxjs';
import { map, share, takeUntil, tap } from 'rxjs/operators';

import { MingaPermission } from 'minga/domain/permissions';
import { RealtimeEventType } from 'minga/domain/realtimeEvents';
import { StudentSection } from 'minga/domain/studentSchedule';
import { mingaSettingTypes } from 'minga/libraries/util';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { PermissionsService } from 'src/app/permissions';
import { RealtimeEvents } from 'src/app/realtime-event/RealtimeEvents';
import { BrightnessService } from 'src/app/services/Brightness';
import { HallPassService } from 'src/app/services/HallPass';
import { UserStorage } from 'src/app/services/UserStorage';
import { MingaSettingsService } from 'src/app/store/Minga/services';
import { closeCurrentOverlay } from 'src/app/util/overlay';

import { BellSchedulePermissionsService } from '@modules/minga-manager/components/mm-bell-schedule/services/bell-schedule-permissions.service';

import {
  getProfileScheduleRoute,
  getToolsScheduleRoute,
} from '@shared/services/student-schedule/utils/student-schedule.utils';

import {
  IStudentIdUploaderDialogResponse,
  StudentIdUploaderDialogService,
} from '../student-id-uploader-dialog';
import { SystemAlertSnackBarService } from '../system-alert-snackbar';
import {
  STUDENT_ID_PHOTO_STORAGE_KEY,
  STUDENT_ID_STORAGE_KEY,
  ViewIdMessage,
} from './constants';
import { ViewIdService } from './services/view-id.service';
import { IdStudentData } from './types';

@Component({
  selector: 'mg-view-id',
  templateUrl: './view-id.component.html',
  styleUrls: ['./view-id.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewIdComponent implements OnInit, OnDestroy {
  // Constants

  public readonly MESSAGES = ViewIdMessage;

  // Cleanup

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // State

  private _hashParam: string;

  private readonly _isLoadingSubject = new BehaviorSubject<boolean>(false);
  public readonly isLoading$ = this._isLoadingSubject.asObservable();

  private readonly _isOfflineSubject = new BehaviorSubject<boolean>(false);
  public readonly isOffline$ = this._isOfflineSubject.asObservable();

  private readonly _studentDataSubject = new BehaviorSubject<IdStudentData>(
    null,
  );
  public readonly studentData$ = this._studentDataSubject.asObservable();

  public readonly showPrint$ = this._route.params.pipe(
    takeUntil(this._destroyedSubject),
    map(params => params?.print ?? false),
  );

  public readonly currentDate$ = timer(0, 1000).pipe(
    map(() => new Date()),
    share(),
  );

  private readonly _hashParam$ = this._route.params.pipe(
    takeUntil(this._destroyedSubject),
    map(params => params.hash),
    tap(hash => (this._hashParam = hash)),
  );

  public readonly isReadOnly$ = this._hashParam$.pipe(
    takeUntil(this._destroyedSubject),
    map(hash => {
      if (hash) return hash !== this._authInfo.authPersonHash;
      return false;
    }),
  );

  public readonly showCurrentClass$ = combineLatest([
    from(this._bellSchedulePermissions.isBellScheduleEnabledForCurrentUser()),
    this.studentData$,
  ]).pipe(
    map(([isBellScheduleEnabled, studentData]) => {
      return isBellScheduleEnabled && !!studentData?.currentClasses;
    }),
  );

  // Subscriptions

  private readonly _fetchHallPassesSubscription = this._realtimeEvents
    .observe([
      RealtimeEventType.HALL_PASS_APPROVED,
      RealtimeEventType.HALL_PASS_ENDED,
    ])
    .pipe(takeUntil(this._destroyedSubject))
    .subscribe(() => this._handlefetchHallPassesSubscription());

  /**
   * this is to pass a dynamic value to the title scale in the
   * mg-overlay-primary, could use the dedicated view-id service for this,
   * but this is actually less complex due to the getSettingValueObs method
   */
  public readonly idClockFontScale$ = this._settingService
    .getSettingValueObs(mingaSettingTypes.ID_CLOCK_FONT_SIZE)
    .pipe(
      map(value => {
        return 1 + Math.min(value, 200) / 100.0;
      }),
    );

  public readonly idCardLayout$ = this.viewId.viewIdSettings$.pipe(
    map(settings => settings.idCardLayout),
  );

  // Constructor

  constructor(
    public viewId: ViewIdService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _authInfo: AuthInfoService,
    private _studentIdUploaderDialog: StudentIdUploaderDialogService,
    private _platform: Platform,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _settingService: MingaSettingsService,
    private _brightnessService: BrightnessService,
    private _localStorage: UserStorage,
    private _realtimeEvents: RealtimeEvents,
    private _hpService: HallPassService,
    private _bellSchedulePermissions: BellSchedulePermissionsService,
    private _permissionService: PermissionsService,
  ) {}

  public ngOnInit(): void {
    this._hashParam$.pipe(takeUntil(this._destroyedSubject)).subscribe(hash => {
      this.loadData(hash ?? this._authInfo.authPersonHash);
    });
    this._setBrightness(1);
  }

  public ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._studentDataSubject.complete();
    this._isLoadingSubject.complete();
    this._isOfflineSubject.complete();
    this._revertBrightnessChange();
    this._fetchHallPassesSubscription.unsubscribe();
  }

  public async loadData(personHash: string) {
    try {
      this._isLoadingSubject.next(true);
      const cachedStudentId = await this._getStudentIdFromLocalStorage(
        personHash,
      );
      this._studentDataSubject.next(cachedStudentId);
      if (cachedStudentId) this._isLoadingSubject.next(false);
      const result = await this._getStudentId(personHash);
      this._studentDataSubject.next(result);
    } catch (e) {
      this._systemAlertSnackBar.error('There was an error loading the id data');
    } finally {
      this._isLoadingSubject.next(false);
    }
  }

  public async handleCloseModal(): Promise<void> {
    await this._router.navigate([{ outlets: { o: null } }]);
  }

  public openSuicidePreventionModal(): void {
    closeCurrentOverlay(this._router).then(() => {
      this._router.navigate([
        '',
        { outlets: { o: ['suicidepreventionhotline'] } },
      ]);
    });
  }

  public print() {
    if (this._platform.SAFARI) {
      /**
       * execCommand is deprecated but there doesn't seem to be any other
       * supported alternative. This is a workaround to allow printing in Safari
       */
      document.execCommand('print', false, null);
    } else {
      window.print();
    }
  }

  public async changeIdPhoto(event: IdStudentData) {
    if (!event) return;

    const dialogRef = this._studentIdUploaderDialog.open({
      data: {
        personHash: event.personHash,
        currentImage: event.idPhoto,
      },
    });
    dialogRef
      .afterClosed()
      .subscribe(async (response: IStudentIdUploaderDialogResponse) => {
        if (response) {
          const fileName = response?.fileName;
          try {
            await this.viewId.uploadStudentIdPhotoChange(
              this._hashParam ?? this._authInfo.authPersonHash,
              fileName,
            );
            const studentId = this._studentDataSubject.getValue();

            let processedImage: Blob;
            if (fileName) {
              processedImage = await this.viewId.processStudentIdPhoto(
                fileName,
              );
            }

            const idPhoto = processedImage
              ? URL.createObjectURL(processedImage)
              : '';

            studentId.idPhoto = idPhoto;
            this._studentDataSubject.next({ ...studentId });
            await this._setStudentIdInLocalStorage(
              this._hashParam ?? this._authInfo.authPersonHash,
              studentId,
            );

            this._systemAlertSnackBar.success('Photo uploaded successfully');
          } catch (_) {
            this._systemAlertSnackBar.error(
              'There was an error uploading the photo',
            );
          }
        }
      });
  }

  public async scheduleClicked(section: StudentSection) {
    const navigatingToProfile =
      this._hashParam && this._authInfo.authPersonHash !== this._hashParam;
    const canViewProfile = await this._permissionService.hasPermission(
      MingaPermission.MINGA_PEOPLE_MANAGE,
    );

    // make sure user has permission to view profile
    if (navigatingToProfile && !canViewProfile) {
      return;
    }

    const route = navigatingToProfile
      ? getProfileScheduleRoute(section.type, this._hashParam)
      : getToolsScheduleRoute(section.type);

    await this.handleCloseModal();
    this._router.navigate(route);
  }

  private async _getStudentId(personHash: string) {
    try {
      const studentId = await this.viewId.fetchStudentId(personHash);
      await this._setStudentIdInLocalStorage(personHash, studentId);
      this._isOfflineSubject.next(false);
      return studentId;
    } catch (e) {
      this._isOfflineSubject.next(true);
      const studentId = await this._getStudentIdFromLocalStorage(personHash);
      return studentId;
    }
  }

  private async _setStudentIdInLocalStorage(
    personHash: string,
    studentId: IdStudentData,
  ) {
    await this._localStorage.setItem<IdStudentData>(
      STUDENT_ID_STORAGE_KEY + personHash,
      studentId,
    );
  }

  private async _getStudentIdFromLocalStorage(personHash: string) {
    const storedStudentId = await this._localStorage.getItem<IdStudentData>(
      STUDENT_ID_STORAGE_KEY + personHash,
    );
    if (!storedStudentId) return null;

    if (storedStudentId.idPhoto) {
      const cachedIdImage = await this._localStorage.getItem<Blob>(
        STUDENT_ID_PHOTO_STORAGE_KEY,
      );
      if (cachedIdImage) {
        storedStudentId.idPhoto = URL.createObjectURL(cachedIdImage);
      }
    }

    return storedStudentId;
  }

  private async _setBrightness(brightness: number) {
    if (!this._brightnessService.deviceSupportsBrightnessChange()) return;

    try {
      // cache the current brightness
      await this._brightnessService.cacheUsersBrightness();
      await this._brightnessService.setBrightness(brightness);
    } catch (e) {
      // lets swallow these errors, underlying service will log them
    }
  }

  private async _handlefetchHallPassesSubscription() {
    const studentIdInfo = this._studentDataSubject.getValue();
    if (!studentIdInfo) return;
    const personHash = this._hashParam ?? this._authInfo.authPersonHash;
    const activePasses = await this._hpService.getHallPassesForRecipient(
      personHash,
    );
    studentIdInfo.activePasses = activePasses;
    this._studentDataSubject.next({ ...studentIdInfo });
  }

  private async _revertBrightnessChange() {
    if (!this._brightnessService.deviceSupportsBrightnessChange()) return;

    try {
      await this._brightnessService.revertBrightness();
    } catch (e) {
      // lets swallow these errors, underlying service will log them
    }
  }
}
